import LeadModal from 'modals/scripts/lead_modal'
import Track from 'tracking_events/scripts/track'
import ParamsFromMarketingEmail from 'marketing/scripts/params_from_marketing_email'

const BUTTON_SELECTOR = '.js-contact-btn:not([disabled])'

class LeadButton {
  static async init () {
    $(document).on('click', BUTTON_SELECTOR, (e) => {
      const listingId = $(e.currentTarget).data('listing-id')
      if (window._formSelected) { window._formSelected = '#lead_form' }
      this.open(listingId)
    })

    if (($(BUTTON_SELECTOR).length > 0) && window.location.search.match(/open_lead_modal=true/)) {
      const listingId = $(BUTTON_SELECTOR).data('listing-id')
      ParamsFromMarketingEmail.maybeUpdateCustomerFields()

      this.open(listingId)
    }
  }

  static open (listingId) {
    LeadModal.init(listingId)

    new Track().send('event', {
      event_category: 'lead_button_click',
      listing_ids: [listingId]
    })
  }
}

export default LeadButton
