import BaseForm from './base_form'
import WebsiteConfigs from 'website_configs/website_configs'
import QueryString from 'utils/query_string'

const FORM_SELECTOR = '[data-role="customer_request_form"]'
const ATTACHMENTS_SELECTOR = '[data-role="attachments"]'
const SUBMIT_BUTTON_SELECTOR = '[role="button"]'
const PRESIGNED_PATH = '/api/s3/presigned_attachment_urls'
const CUSTOMER_REQUEST_PATH = '/api/customer_requests'
const SPINNER_ELEMENT = '<i id="spinner" class="ml-2 fa fa-spinner fa-spin" />'
const FILE_SIZE_LIMIT = 10 * 1024 * 1024
const FILE_COUNT_LIMIT = WebsiteConfigs.contactFormAttachFilesLimit || 9
const IGNORED_EXTENSION_REGEXP = /\.(?:exe|php|svg)/
const IGNORED_FILE_TYPES = ['image/svg+xml',
  'text/php',
  'application/octet-stream',
  'application/x-msdownload',
  'application/vnd.microsoft.portable-executable']

class CustomerRequestForm extends BaseForm {
  static async init () {
    return new CustomerRequestForm()
  }

  constructor () {
    super(FORM_SELECTOR)

    this.bindCallbacks()

    $(ATTACHMENTS_SELECTOR).on('change', (e) => {
      this.attachments = this.attachments || []

      const attachmentsInput = $(e.target)
      const attachmentsLabel = attachmentsInput.siblings('label')
      const submitButton = attachmentsInput.parent().parent().find(SUBMIT_BUTTON_SELECTOR)

      if (attachmentsInput.siblings('ul').length === 0) { attachmentsInput.parent().append('<ul></ul>') }
      const filesList = attachmentsInput.siblings('ul')
      const remainingFileCountLimit = FILE_COUNT_LIMIT - this.attachments.length

      const promises = Array.from(e.target.files).slice(0, remainingFileCountLimit).map((file) => {
        return new Promise((resolve, reject) => {
          if (file.size > FILE_SIZE_LIMIT) {
            return resolve()
          }

          if (file.name.match(IGNORED_EXTENSION_REGEXP) || IGNORED_FILE_TYPES.includes(file.type)) {
            if (attachmentsInput.siblings('p').length === 0) { attachmentsInput.parent().append('<p style="color: #f00"></p>') }
            const errorMessage = `${I18n.t('unsupported_file_type')} ${file.name}`
            attachmentsInput.siblings('p').text(errorMessage)
            return resolve()
          } else {
            if (attachmentsInput.siblings('p').length !== 0) { attachmentsInput.siblings('p').text('') }
          }

          if (!attachmentsInput.attr('disabled')) attachmentsInput.attr('disabled', true)
          if (!attachmentsLabel.hasClass('disabled')) attachmentsLabel.addClass('disabled')
          if (!submitButton.hasClass('disabled')) {
            submitButton.addClass('disabled').prepend(SPINNER_ELEMENT)
          }

          $.ajax({
            url: PRESIGNED_PATH,
            method: 'POST',
            dataType: 'json',
            data: {
              filename: file.name
            }
          }).done((result) => {
            const formData = new FormData()
            for (var key in result.fields) {
              formData.append(key, result.fields[key])
            }
            formData.append('Content-Type', file.type)
            formData.append('file', file, file.name)

            $.ajax({
              type: 'POST',
              url: result.url,
              data: formData,
              processData: false,
              contentType: false,
              dataType: 'xml'
            }).done((xml) => {
              const location = $(xml).find('Location').text()
              this.attachments.push(location)
              filesList.append('<li>' + file.name + '</li>')
              return resolve(location)
            }).fail((error) => {
              return reject(error)
            })
          }).fail((error) => {
            console.log(error)
          })
        })
      })

      Promise.all(promises).finally((_) => {
        if (this.attachments.length < FILE_COUNT_LIMIT) {
          attachmentsInput.attr('disabled', false)
          attachmentsLabel.removeClass('disabled')
        }
        submitButton.removeClass('disabled')
        $('#spinner').remove()
      })
    })
  }

  onSubmit () {
    this.disabledSubmitButton()

    $.ajax({
      url: CUSTOMER_REQUEST_PATH,
      method: 'POST',
      dataType: 'json',
      data: {
        listing_id: this.extractListingId(),
        request: { source: document.location.href,
          utm_params: JSON.parse(sessionStorage.getItem('utm_params') || '{}'),
          attachments: this.attachments,
          extra_data: { name: this.extractFormOrPageName() },
          ...this.formData() }
      }
    }).done(() => {
      this.onSuccess()

      this.registerEventToGoogleAnalytics()

      if (window.gtagReportConversion) {
        window.gtagReportConversion()
      }
    }).fail((error) => {
      this.onError(error)
    }).always(() => {
      this.enableSubmitButton()
    })
  }

  registerEventToGoogleAnalytics () {
    const formSelector = $(FORM_SELECTOR)

    if (window.gtag && formSelector.length > 0) {
      // add data attributes to the custom page/landing page section form tag ex: data-event-category="Form"
      const category = $(formSelector[0]).data('event-category')
      const action = $(formSelector[0]).data('event-action')
      const label = $(formSelector[0]).data('event-label')

      if (category && action) {
        window.gtag('event', action, {
          'event_category': category,
          'event_label': label
        })
      }
    }
  }

  extractListingId () {
    const listingId = $('.listing-show').data('listing-id')

    if (this.$form.length > 0 && window._formSelected) {
      const selectedForm = $(this.$form.filter(window._formSelected))
      return selectedForm.data('lead') ? listingId : null
    } else {
      return $(this.$form[0]).data('lead') ? listingId : null
    }
  }

  extractFormOrPageName () {
    return $(this.$form.filter(window._formSelected)).data('name') || $(this.$form[0]).data('name') || document.title
  }

  bindCallbacks () {
    const $customModal = $('#customModal')
    const $multipleModalButton = $('.multiple-modal-button')

    if ($customModal.length) {
      $customModal.on('show.bs.modal', (e) => {
        const recipient = $(e.relatedTarget).data('whatever')

        $customModal.find('.modal-title').text(recipient)
        $customModal.find('#modal-source').val(recipient)
      })

      $customModal.on('hidden.bs.modal', () => {
        this.reset()
        this.prefillForm()
      })
    }

    if ($multipleModalButton.length) {
      $multipleModalButton.on('click', () => {
        this.reset()
        this.prefillForm()
      })
    }
  }
}

export default CustomerRequestForm
