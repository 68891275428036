/* eslint-env jquery */

// scripts

import Rails from 'rails-ujs'

import 'jquery-validation'
import 'jquery-ui/ui/core'
import 'jquery-ui/ui/widget'
import 'jquery-ui/ui/widgets/autocomplete'
import 'bootstrap/js/dist/alert'
import 'bootstrap/js/dist/util'
import 'bootstrap/js/dist/tab'
import 'bootstrap/js/dist/button'
import 'bootstrap/js/dist/modal'
import 'bootstrap/js/dist/dropdown'
import '../application.scss'
import 'pushbar.js/src/pushbar.css'
import I18n from '../utils/i18n'
import 'fonts/scripts/import_fonts'

import DirectRequestForm from 'forms/scripts/direct_request_form'
import HeaderMenu from 'navigation/scripts/header_menu'
import SortingSelect from 'listings/scripts/sorting_select'
import SortingToggle from 'listings/scripts/sorting_toggle'
import CategorySelect from 'navigation/scripts/category_select'
import PerPageSelect from 'pagination/scripts/per_page_select'
import SearchAutocomplete from 'navigation/scripts/search_autocomplete'
import SearchInput from 'navigation/scripts/search_input'
import BlogPostsSearchInput from 'blog_posts/scripts/blog_posts_search_input'
import LeadButton from 'listings/scripts/lead_button'
import RequestQuoteButton from 'listings/scripts/request_quote_button'
import EmailListingButton from 'listings/scripts/send_email_button'
import CurrenciesDropdown from 'currencies_widget/scripts/currencies_dropdown'
import ListingPrice from 'listings/scripts/listing_price'
import ListingFiltersToggle from 'listings/scripts/listing_filters_toggle'
import NewsletterSubscriptionForm from 'forms/scripts/newsletter_subscription_form'
import ContactEvent from 'tracking/scripts/contact_event'
import NewsletterSubscriptionButton from 'newsletter_subscription/scripts/newsletter_subscription_button'
import AdvancedFilters from 'navigation/scripts/advanced_filters'
import SideAdvancedFilters from 'navigation/scripts/side_advanced_filters'
import UserDefinedFilters from 'navigation/scripts/user_defined_filters'
import InlineLeadForm from 'listings/scripts/inline_lead_form'
import OffcanvasNavToggle from 'navigation/scripts/offcanvas_nav_toggle'
import ToTheTop from 'navigation/scripts/to_the_top'
import SideCategoriesNavToggle from 'navigation/scripts/side_categories_nav_toggle'
import CustomerRequestForm from 'forms/scripts/customer_request_form'
import MultiStepForm from 'forms/scripts/multi_step_form'
import AdvancedNewsletterSubscriptionForm from 'forms/scripts/advanced_newsletter_form'
import PrintButtons from 'listings/scripts/print_buttons'
import SortingMoreButton from 'listings/scripts/sorting_more_button'
import PriceConverting from 'price_converting/scripts/price_converting'
import TabsScrolling from 'bootstrap/scripts/tabs_scrolling'
import SearchResultsEvents from 'listings/scripts/search_results_events'
import ListingEvents from 'listings/scripts/listing_events'
import FinancingEvents from 'listings/scripts/financing_events'
import CommonPageviewTracking from 'tracking_events/scripts/common_pageview_tracking'
import LazyLoad from 'vanilla-lazyload/dist/lazyload'
import { executeLazyScript } from 'utils/lazy_scripts'

import Color from '../utils/color'
window.I18n = I18n

window.Color = Color

window.$ = window.jQuery = jQuery

Rails.start()

$(function () {
  HeaderMenu.init()
  ListingPrice.init()
  ListingFiltersToggle.init()
  CurrenciesDropdown.init()
  CategorySelect.init()
  PerPageSelect.init()
  SortingSelect.init()
  SortingToggle.init()
  SearchAutocomplete.init()
  SearchInput.init()
  BlogPostsSearchInput.init()
  LeadButton.init()
  RequestQuoteButton.init()
  EmailListingButton.init()
  DirectRequestForm.init()
  NewsletterSubscriptionForm.init()
  AdvancedNewsletterSubscriptionForm.init()
  ContactEvent.init()
  NewsletterSubscriptionButton.init()
  AdvancedFilters.init()
  SideAdvancedFilters.init()
  UserDefinedFilters.init()
  InlineLeadForm.init()
  SideCategoriesNavToggle.init()
  CustomerRequestForm.init()
  MultiStepForm.init()
  PrintButtons.init()
  SortingMoreButton.init()
  OffcanvasNavToggle.init()
  ToTheTop.init()
  PriceConverting.init()
  TabsScrolling.init()
  SearchResultsEvents.init()
  ListingEvents.init()
  FinancingEvents.init()
  CommonPageviewTracking.init()

  window.lazyLoadInstance = new LazyLoad({
    unobserve_entered: true,
    callback_enter: executeLazyScript
  })
})
