class MultiStepForm {
  static async init () {
    const navListItems = $('.setup-panel .btn')
    const allWells = $('.setup-content')
    const allNextBtn = $('.nextBtn')
    const formGroup = $('.form-group')

    allWells.hide()

    navListItems.click(function (e) {
      e.preventDefault()
      const $target = $($(this).attr('href'))
      const $item = $(this)

      if (!$item.hasClass('disabled')) {
        $item.addClass('btn-primary').removeClass('btn-light')
        allWells.hide()
        $target.show()
        $target.find('input:eq(0)').focus()
      }
    })

    allNextBtn.click(function () {
      let curStep = $(this).closest('.setup-content')
      let curStepBtn = curStep.attr('id')
      let nextStepWizard = $('.setup-panel div a[href="#' + curStepBtn + '"]').parent().next().children('a')
      let curInputs = curStep.find("input[type='text'],input[type='url'],input[type='checkbox'],input[type='radio'],select")
      let isValid = true

      formGroup.removeClass('has-error')

      for (let i = 0; i < curInputs.length; i++) {
        if (!curInputs[i].validity.valid) {
          isValid = false
          $(curInputs[i]).closest(formGroup).addClass('has-error')
        }
      }

      if (isValid) { nextStepWizard.removeAttr('disabled').trigger('click') }
    })

    $('.setup-panel a.btn-primary').trigger('click')
  }
}

export default MultiStepForm
