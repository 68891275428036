const SEARCH_INPUT_SELECTOR = '[data-role="searchbar"]'

class SearchInput {
  static async init () {
    if (!$(SEARCH_INPUT_SELECTOR)[0]) return

    const dataQuery = jQuery.parseJSON($('code[data-query-params]').attr('data-query-params') || '{}')
    const listingsPath = (dataQuery.path || 'listings') + '?'

    const cleanedDataQuery = dataQuery
    delete cleanedDataQuery['path']
    delete cleanedDataQuery['q']

    $(SEARCH_INPUT_SELECTOR).on('search', (e) => {
      if (e.currentTarget.value === '') {
        window.location.href = listingsPath + $.param(cleanedDataQuery)
      }
    })
  }
}

export default SearchInput
