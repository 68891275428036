import WebsiteConfigs from 'website_configs/website_configs'
import Cookies from 'js-cookie'
import validPaypalCurrencies from 'paypal/currencies'

const ISO_SYMBOLS = {
  'usd': '$',
  'eur': '€',
  'gbp': '£',
  'jpy': '¥',
  'aud': 'A$',
  'cad': 'C$',
  'chf': 'Fr',
  'clp': '$',
  'cny': '元',
  'sek': 'kr',
  'nzd': 'NZ$',
  'mxn': '$',
  'sgd': 'S$',
  'hkd': 'HK$',
  'nok': 'kr',
  'krw': '₩',
  'try': '₺',
  'rub': '₽',
  'inr': '₹',
  'brl': 'R$',
  'zar': 'R',
  'dkk': 'kr.',
  'sar': '﷼',
  'ghs': 'GH₵',
  'ars': '$',
  'myr': 'RM',
  'pln': 'zł'
}

class Currency {
  static currencySymbol (isoCode) {
    return ISO_SYMBOLS[isoCode.toLowerCase()]
  }

  static getCurrency () {
    let currency = (Cookies.get('currency') || WebsiteConfigs.currency).toUpperCase()
    const paypalEnabled = !!$('[data-paypal-key]').data('paypal-key')

    if (paypalEnabled && !validPaypalCurrencies.includes(currency)) {
      currency = $('[data-paypal-default-currency]').data('paypal-default-currency').toUpperCase()
    }

    return currency
  }

  static formatPrice (price, hideIsoCode = false, decimalPlaces) {
    const currency = this.getCurrency()
    const symbol = this.currencySymbol(currency)

    if (price && parseFloat(price) > 0) {
      let priceFormat = symbol + parseFloat(price).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')

      if (decimalPlaces === 0) {
        priceFormat = priceFormat.replace(/\.\d\d/g, '')
      }

      if (!hideIsoCode) {
        return `${priceFormat} (${currency.toUpperCase()})`
      }

      return priceFormat
    }

    return `${symbol}0.00`
  }
}

export default Currency
