
import Cookies from 'js-cookie'

const COOKIE_CATEGORY = 'functional'

class PerPageSelect {
  static async init () {
    $('.select-per-page').click((event) => {
      event.preventDefault()
      const perPage = event.target.text
      if (!(window.orejime && window.orejime.internals.manager.consents[COOKIE_CATEGORY] !== true)) {
        Cookies.set('per_page', perPage, { expires: 30, path: '/' })
      }
      const href = window.location.href
      const firstPage = href.replace(/[?&]page=\d+/, '')
      window.location.href = firstPage
    })
  }
}
export default PerPageSelect
