import Track from 'tracking_events/scripts/track'

const TAYCOR_ID_REGEXP = /https:\/\/app\.taycor\.com\/fw\/(.+)\//

class FinancingEvents {
  static init () {
    if ($('.listings-index').length > 0) {
      $('[data-role="financing"]').on('click', (event) => {
        this.trackFinancingEventAndOpenPage(event, 'listings_index')
      })
    }

    if ($('.listing-show').length > 0) {
      $('[data-role="financing"]').on('click', (event) => {
        this.trackFinancingEventAndOpenPage(event, 'listings_show')
      })
    }
  }

  static trackFinancingEventAndOpenPage (event, pageType) {
    const url = $(event.target).attr('href')
    const taycorIdMatch = url.match(TAYCOR_ID_REGEXP)

    if (taycorIdMatch && taycorIdMatch[1]) {
      new Track().send('event', {
        event_category: 'taycor_link_click',
        event_action: pageType,
        event_value: taycorIdMatch[1]
      })
    }
  }
}

export default FinancingEvents
