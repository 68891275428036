class HeaderMenu {
  static async init () {
    this._setBindings()
  }
  static _setBindings () {
    $('.primary-nav__dropdown > .primary-nav__link > a, .dropdown-children').on('click', (e) => {
      if (e.currentTarget.href) {
        if (this.isTouchDevice) {
          e.preventDefault()
        } else {
          if (e.currentTarget.target === '_blank') {
            window.open(e.currentTarget.href, '_blank', 'noopener').focus()
          } else {
            window.location.href = e.currentTarget.href
          }
        }
      }
    })

    $('.primary-nav__dropdown, .google-translate-dropdown').on('mouseenter', (e) => {
      $(e.currentTarget).dropdown('toggle')
    }).on('mouseleave', () => {
      $('.primary-nav__dropdown-menu, .google-translate__dropdown-menu, .google-translate').removeClass('show')
    })

    $('.primary-nav .dropdown-item:not(.dropdown-childrens), .mega-menu > a').on('click', (e) => {
      if (e.currentTarget.href && e.currentTarget.href !== '#') {
        if (e.currentTarget.target === '_blank') {
          const newWindow = window.open(e.currentTarget.href, '_blank', 'noopener')
          if (newWindow) newWindow.focus()
        } else {
          window.location.href = e.currentTarget.href
        }
      }
    })
  }

  static get isTouchDevice () {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0
  }
}

export default HeaderMenu
