class SideCategoriesNavToggle {
  static async init () {
    const $siteCategoriesToggles = $('[data-role="side-categories-toggle"]')
    const $siteCategories = $('[data-role="side-categories"]')
    $siteCategoriesToggles.each(function () {
      $(this).on('click', (e) => {
        e.preventDefault()
        $siteCategoriesToggles.toggleClass('clicked').find('> .fa').toggleClass('fa-list-ul fa-times fa-solid')
        $siteCategories.stop(true, true).slideToggle()
      })
    })
  }
}

export default SideCategoriesNavToggle
