import QueryString from 'utils/query_string'

class ParamsFromMarketingEmail {
  static maybeUpdateCustomerFields () {
    const params = this.params()
    const customer = {
      email: params.email,
      first_name: params.first_name,
      last_name: params.last_name,
      location: params.location,
      phone: params.phone
    }
    localStorage.setItem('contacts', decodeURI(JSON.stringify(customer)))
  }

  static params () {
    return QueryString.parse(decodeURIComponent(window.location.search.replace(/\+/g, '%20')))
  }
}

export default ParamsFromMarketingEmail
