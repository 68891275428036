import en from './en.json'
import de from './de.json'
import fr from './fr.json'
import nl from './nl.json'
import es from './es.json'
import it from './it.json'
import pt from './pt.json'
import da from './da.json'
import ru from './ru.json'
import zh from './zh.json'
import cs from './cs.json'
import sk from './sk.json'

export default {
  en,
  de,
  fr,
  it,
  nl,
  es,
  pt,
  da,
  ru,
  zh,
  cs,
  sk
}
