class SortingToggle {
  static async init () {
    const customSortingToggle = $('.custom-switch')
    if (!customSortingToggle.length) return

    const dataQuery = jQuery.parseJSON($('code[data-query-params]').attr('data-query-params') || '{}')
    const listingsPath = (dataQuery.path || 'listings') + '?'
    const cleanedDataQuery = dataQuery
    delete cleanedDataQuery['path']

    const sortBy = customSortingToggle[0].dataset['sort_by']
    const direction = customSortingToggle[0].dataset['direction']

    customSortingToggle.click((event) => {
      event.preventDefault()

      if (dataQuery.sort_by !== sortBy) {
        location.replace(listingsPath + $.param({ ...cleanedDataQuery, sort_by: sortBy, sort_by_direction: direction }))
      } else {
        delete cleanedDataQuery['sort_by']
        delete cleanedDataQuery['sort_by_direction']
        location.replace(listingsPath + $.param(cleanedDataQuery))
      }
    })
  }
}
export default SortingToggle
