import Track from './track'

class CommonPageviewTracking {
  static init () {
    if ($('.listings-index').length === 0 && $('.listing-show').length === 0) {
      const data = {}
      const eventCategory = $('[data-event-category]').data('eventCategory')
      if (eventCategory) {
        data['event_category'] = eventCategory
      }
      new Track().trackView(data)
    }
  }
}

export default CommonPageviewTracking
