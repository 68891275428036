import EmailListingForm from 'forms/scripts/email_listing_form'
import DynamicModal from 'modals/scripts/dynamic_modal'
import Track from 'tracking_events/scripts/track'

const MODAL_SELECTOR = '#email_modal'

class ListingSendEmailButton {
  static async init () {
    $('#js-email').on('click', (e) => {
      e.preventDefault()

      const modal = new DynamicModal(MODAL_SELECTOR, { size: 'md' })

      EmailListingForm.render(modal.$body, { listingId: $(e.currentTarget).data('listing-id'), messageField: $(e.currentTarget).data('message-field'), emailLang: $(e.currentTarget).data('email-lang') })

      new Track().send('event', {
        event_category: 'send_email_click'
      })

      modal.open()
    })
  }
}

export default ListingSendEmailButton
