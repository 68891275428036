import Cookies from 'js-cookie'
import locales from 'locales'

class I18n {
  static t (key, options) {
    const translations = locales[(options && options.lang) || this.lang.match(/^\w{2}/)[0]]

    const translation = key.split('.').reduce((acc, k) => {
      return acc && acc[k]
    }, translations)

    if (!translation && process.env.NODE_ENV !== 'production') {
      console.warn(`Missing translation: ${key}`)
    }

    if (translation) {
      if (options) {
        return translation.replace(/%\{([^}]+)}/g, (match, key) => options[key] || match)
      }

      return translation
    } else if (!options || options.fallback !== false) {
      return this.t(key, { lang: 'en', fallback: false, ...options })
    }
  }

  static has (key) {
    return !!this.t(key, { fallback: false })
  }

  static get lang () {
    if (!this._lang) {
      const googleLang = ((Cookies.get('googtrans') || '').match(/\/[\w-]{2,5}\/([\w-]{2,5})/) || [])[1]
      this._lang = googleLang || $('html').attr('lang') || 'en'
    }

    return this._lang
  }
}

export default I18n
