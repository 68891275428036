import NewsletterSubscription from 'newsletter_subscription/scripts/newsletter_subscription'
import DynamicModal from 'modals/scripts/dynamic_modal'
import WebsiteConfigs from 'website_configs/website_configs'

const MODAL_SELECTOR = '#newsletter_subscription_modal'

class NewsletterSubscriptionButton {
  static async init () {
    $('.js-newsletter-subscription-btn').on('click', (e) => {
      const $captcha = $(e.currentTarget).find('.d-none.captcha')[0]
      const captchaHtml = $captcha ? $captcha.innerHTML : null
      const size = $captcha ? 'md' : 'sm'
      e.preventDefault()
      const modal = new DynamicModal(MODAL_SELECTOR, { size: size, header_title: WebsiteConfigs.customNewsletterButtonTitle || I18n.t('newsletter_signup') })
      NewsletterSubscription.render(modal.$body, captchaHtml)

      modal.open()
    })
  }
}

export default NewsletterSubscriptionButton
