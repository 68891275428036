/* global I18n */

import Choices from 'choices.js'
import WebsiteConfigs from 'website_configs/website_configs'

class AdvancedFilters {
  static async init () {
    if (!document.querySelector('.advanced-filters')) return

    const fields = ['manufacturer', 'model', 'year']
    const dataQuery = JSON.parse(document.querySelector('code[data-query-params]')?.getAttribute('data-query-params') || '{}')
    const listingsPath = (dataQuery.path || 'listings') + '?'
    const isNotranslate = I18n.lang !== WebsiteConfigs.primaryLanguageCode

    const cleanedDataQuery = { ...dataQuery }
    delete cleanedDataQuery['path']

    const debounce = (func, delay) => {
      let timer
      return (...args) => {
        clearTimeout(timer)
        timer = setTimeout(() => func(...args), delay)
      }
    }

    const fetchOptions = async (fieldName, keyword = '') => {
      const params = new URLSearchParams({ field: fieldName, keyword: keyword, ...dataQuery })
      const response = await fetch(`/api/filterable_fields_values/?${params}`)
      const data = await response.json()

      let options = Object.entries(data).map(([name, count], index) => ({
        value: name,
        label: `${name} (${count})`
      }))

      if (fieldName === 'year') options.reverse()
      return options
    }

    fields.forEach(async (fieldName) => {
      const fieldElement = document.getElementById(`js-${fieldName}-select`)
      if (!fieldElement) return

      const itemClasses = ['choices__item']

      if (isNotranslate) {
        itemClasses.push('notranslate')
      }

      const choices = new Choices(fieldElement, {
        placeholderValue: fieldElement?.dataset?.translation || I18n.t(fieldName),
        placeholder: true,
        searchEnabled: true,
        singleModeForMultiSelect: true,
        removeItemButton: true,
        searchResultLimit: 10,
        shouldSort: false,
        removeItemButtonAlignLeft: true,
        classNames: {
          item: itemClasses,
          containerOuter: ['choices', fieldName]
        }
      })

      const loadOptions = async (keyword = '') => {
        const options = await fetchOptions(fieldName, keyword)
        choices.clearChoices()
        choices.setChoices(options, 'value', 'label', true)
      }

      await loadOptions()

      fieldElement.addEventListener('search', debounce((event) => {
        loadOptions(event.detail.value)
      }, 300))

      fieldElement.addEventListener('change', (event) => {
        const selectedValues = choices.getValue(true)

        if (selectedValues.length) {
          if (selectedValues.length > 1) {
            choices.removeActiveItemsByValue(selectedValues[0])
            cleanedDataQuery[fieldName] = [selectedValues[1]]
          } else {
            cleanedDataQuery[fieldName] = selectedValues
          }
        } else {
          delete cleanedDataQuery[fieldName]
          delete cleanedDataQuery[`selected_${fieldName}s`]
        }

        window.location.href = listingsPath + new URLSearchParams(cleanedDataQuery)
      })

      const selectedValues = Array.isArray(cleanedDataQuery[fieldName]) ? cleanedDataQuery[fieldName] : [cleanedDataQuery[fieldName]]
      choices.setChoiceByValue(selectedValues)
    })
  }
}

export default AdvancedFilters
