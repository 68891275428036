import Cookies from 'js-cookie'

class CurrenciesBaseWidget {
  constructor (linksSelector) {
    this.$currencyLinks = $(linksSelector)

    if (this.$currencyLinks.length > 0) {
      this._bindCallbacks()
    }
  }

  _bindCallbacks () {
    this.$currencyLinks.on('click', (event) => {
      const currency = $(event.target).parent().data('currency') || $(event.target).data('currency')
      Cookies.set('currency', currency, { path: '/' })
      window.location.reload()
    })
  }

  selectedCurrency () {
    return Cookies.get('currency')
  }
}

export default CurrenciesBaseWidget
