class DynamicModal {
  constructor (modalSelector, options) {
    this.modalSelector = modalSelector
    this.options = options || {}
  }

  open () {
    this.$modal.show()
  }

  get $body () {
    return this.$modal.find('.modal-body')
  }

  get $modal () {
    const $element = $(this.modalSelector)

    if ($element.length === 0) {
      this.render()
    }

    return $(this.modalSelector).modal()
  }

  render () {
    $('body').append(`
      <div id="${this.modalSelector.replace('#', '')}" class="modal fade" aria-hidden="true" role="dialog" tabindex="-1">
        <div class="modal-dialog ${this.options.size ? `modal-${this.options.size}` : ''}" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h6 class="m-0">
                ${this.options.header_title || ''}
              </h6>
              <button aria-label="Close" class="close" data-dismiss="modal" type="button">
                <span aria-hidden="true"> ×</span>
              </button>
            </div>
            <div class="modal-body"></div>
          </div>
        </div>
      </div>
    `)
  }
}

export default DynamicModal
