/* global I18n */

class Pushbar {
  constructor (config = { overlay: true, blur: false }) {
    this.activeId = null
    this.activeElement = null
    this.overlayElement = null
    this.config = config
  }

  emitOpening () {
    const event = new CustomEvent('pushbar_opening', { bubbles: true, detail: { element: this.activeElement, id: this.activeId } })
    this.activeElement.dispatchEvent(event)
  }

  emitClosing () {
    const event = new CustomEvent('pushbar_closing', { bubbles: true, detail: { element: this.activeElement, id: this.activeId } })
    this.activeElement.dispatchEvent(event)
  }

  handleOpenEvent (e) {
    e.preventDefault()
    const pushbarId = e.currentTarget.getAttribute('data-pushbar-target')
    this.open(pushbarId)
  }

  handleCloseEvent (e) {
    e.preventDefault()
    this.close()
  }

  handleKeyEvent (e) {
    if (e.keyCode === 27) this.close()
  }

  bindEvents () {
    const triggers = $('[data-pushbar-target]')
    const closers = $('[data-pushbar-close]')
    const drawerDropdowns = $('[data-role="drawer-dropdown"]')

    triggers.each((_, trigger) => trigger.addEventListener('click', e => this.handleOpenEvent(e), false))
    closers.each((_, closer) => closer.addEventListener('click', e => this.handleCloseEvent(e), false))
    if (this.overlayElement) {
      this.overlayElement.addEventListener('click', e => this.handleCloseEvent(e), false)
    }

    document.addEventListener('keyup', e => this.handleKeyEvent(e))

    drawerDropdowns.on('click', (event) => {
      const link = $(event.target).attr('href')

      if (!link || link === '#') {
        const chevronIcon = $(event.currentTarget).find('.chevron-icon')
        const headerItemChildren = $(event.currentTarget.nextElementSibling)
        headerItemChildren.slideToggle(350)
        chevronIcon.toggleClass('fa-chevron-up fa-chevron-down')
      }
    })
  }

  open (pushbarId) {
    if (this.activeId === String(pushbarId) || !pushbarId) return
    if (this.activeId && this.activeId !== String(pushbarId)) this.close()

    this.render()

    this.activeId = pushbarId
    this.activeElement = document.querySelector(`[data-pushbar-id="${this.activeId}"]`)
    if (!this.activeElement) return
    this.emitOpening()
    this.activeElement.classList.add('opened')
    const pageRootElement = document.querySelector('html')
    pageRootElement.classList.add('pushbar_locked')
    pageRootElement.setAttribute('pushbar', pushbarId)
  }

  close () {
    if (!this.activeId) return
    this.emitClosing()
    this.activeElement.classList.remove('opened')
    const pageRootElement = document.querySelector('html')
    pageRootElement.classList.remove('pushbar_locked')
    pageRootElement.removeAttribute('pushbar')
    this.activeId = null
    this.activeElement = null
  }

  render () {
    if (this.$pushbarContainer) return

    if (this.config.overlay) {
      this.overlayElement = document.createElement('div')
      this.overlayElement.classList.add('pushbar_overlay')
      document.querySelector('body').appendChild(this.overlayElement)
    }

    if (this.config.blur) {
      const mainContent = document.querySelector('.pushbar_main_content')
      if (mainContent) {
        mainContent.classList.add('pushbar_blur')
      }
    }

    const $siteHeader = $('#site-header')
    const $primaryNav = $('.primary-nav__navbar', $siteHeader)
    this.$pushbarContainer = $(this.template).prependTo(document.body)

    $primaryNav.clone().appendTo('.mobile-menu-wrapper', this.$pushbarContainer).find('> li').each(function () {
      const $li = $(this)
      const $dropdown = $('> .dropdown', $li)
      const $children = $('<div class="children" style="display: none;"></div>')

      if ($dropdown.length) {
        const listHeaderName = $('.dropdown-toggle', $dropdown).text()
        const listHeaderLink = $('.dropdown-toggle', $dropdown).children().attr('href') || '#'

        $(`<div class="d-flex justify-content-between" data-role="drawer-dropdown">
            <a href="${listHeaderLink}" class="px-3 py-2 w-100 flex-grow-1 flex-shrink-0 flex-75">${listHeaderName}</a>
            <div class="px-4 py-2 flex-grow-1 flex-shrink-0 flex-25"><i class="chevron-icon fa fa-chevron-down"></i></div>
          </div>`).appendTo($li)

        $('.dropdown-item', $li).each(function () {
          const listSubHeaderName = $(this).text()
          const listSubHeaderLink = $(this).attr('href')

          if ($(this).hasClass('dropdown-children')) {
            const subHeader = `
              <div class="d-flex justify-content-between w-100" data-role="drawer-dropdown">
                <a href="${listSubHeaderLink}" class="px-3 py-2 font-weight-normal w-100 flex-grow-1 flex-shrink-0 flex-75">${listSubHeaderName}</a>
                <div class="px-4 py-2 flex-grow-1 flex-shrink-0 flex-25"><i class="chevron-icon fa fa-chevron-down text-muted"></i></div>
              </div>`

            $(subHeader).appendTo($children)
          } else {
            $(this).removeClass('dropdown-item').appendTo($children)
          }
        })

        $children.appendTo($li)
      } else {
        $li.children().addClass('w-100 d-block px-3 py-2')
      }
      $dropdown.remove().detach()
    })

    this.bindEvents()

    this.$pushbarContainer.addClass('ready')
  }

  get template () {
    return `
      <div class="mobile-menu-container" data-pushbar-direction="left" data-pushbar-id="mobile-menu" id="pushbar-container">
        <div class="mobile-menu-wrapper"></div>
        <div class="mobile-menu-close mt-3">
            <button class="w-100 btn btn-primary" data-pushbar-close=""><i class="fa fa-times fa-solid mr-2"></i>${I18n.t('close')}</button>
        </div>
      </div>
    `
  }
}

class OffcanvasNavToggle {
  static async init () {
    const $siteHeader = $('#site-header')
    const $humburgerMenu = $('[data-role="hamburger-menu"]', $siteHeader)
    const $humburgerIcon = $('.hamburger', $humburgerMenu)

    const pushbar = new Pushbar({
      blur: true,
      overlay: true
    })

    $humburgerMenu.on('click', () => {
      pushbar.open('mobile-menu')
      $humburgerIcon.toggleClass('is-active')
    })

    $(window).on('pushbar_closing', () => {
      $humburgerIcon.removeClass('is-active')
    })
  }
}

export default OffcanvasNavToggle
