class SideAdvancedFilters {
  static async init () {
    if ($('#side-advanced-filters').length) return new SideAdvancedFilters()
  }

  constructor () {
    this.fields = ['manufacturers', 'models', 'conditions', 'categories', 'subcategories', 'countries', 'locations']
    this.forms = ['prices', 'years']

    const dataQuery = jQuery.parseJSON($('code[data-query-params]').attr('data-query-params') || '{}')
    this.listingsPath = (dataQuery.path || 'listings') + '?'

    const cleanedDataQuery = dataQuery
    delete cleanedDataQuery['path']
    this.cleanedDataQuery = cleanedDataQuery

    this.bindEvents()
  }

  bindEvents () {
    $.each(this.forms, (index, formName) => {
      $('button#apply-' + formName).on('click', (e) => {
        e.preventDefault()

        window.location.href = this.listingsPath + $.param(this.cleanedDataQuery) + '&' + $('form#' + formName).serialize()
      })

      $('button#clear-' + formName).on('click', (e) => {
        e.preventDefault()
        const cleanedPriceDataQuery = this.cleanedDataQuery
        delete cleanedPriceDataQuery['min_' + formName.slice(0, -1)]
        delete cleanedPriceDataQuery['max_' + formName.slice(0, -1)]

        window.location.href = this.listingsPath + $.param(cleanedPriceDataQuery)
      })
    })

    $.each(this.fields, (index, fieldName) => {
      if ($('#filter_' + fieldName).length) {
        this.loadOptions(fieldName)
      }

      $(document).on('click', '#header_' + fieldName, (e) => {
        const currentForm = $(document).find('#body_' + fieldName)

        $(e.currentTarget).find('.fa-chevron-up, .fa-chevron-down').toggleClass('fa-chevron-up fa-chevron-down')
        $(e.currentTarget).find('.fa-plus, .fa-minus').toggleClass('fa-plus fa-minus')

        if (currentForm.hasClass('hidden')) {
          currentForm.removeClass('hidden')
        } else if (currentForm.length === 0) {
          $(e.currentTarget).parent().attr('id', 'filter_' + fieldName)

          this.loadOptions(fieldName)
        } else {
          currentForm.addClass('hidden')
        }
      })
    })
  }

  loadOptions (fieldName) {
    $.ajax({
      url: '/api/advanced_filters?' + $.param({ field: fieldName, ...this.cleanedDataQuery }),
      dataType: 'json'
    }).done((data) => {
      let element = $('#filter_' + fieldName)

      element.html(data.html)

      if ($('#show_more_' + fieldName).length) {
        let options = $('#options_' + fieldName)[0]
        if (options.scrollHeight - options.clientHeight > 3) {
          $('#show_more_' + fieldName).fadeIn(100)
        }
      }

      $('#show_more_' + fieldName).click((e) => {
        $('#options_' + fieldName).addClass('list-unstyled--full')

        $('#options_' + fieldName).find('li').removeClass('d-none')

        $(e.currentTarget).remove()
      })
    }).fail((error) => console.log(error))
  }
}

export default SideAdvancedFilters
