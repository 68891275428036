const DEFAULT_FIELDS = ['first_name', 'last_name', 'phone', 'company', 'email']

class AdvancedNewsletterForm {
  static async init () {
    return new AdvancedNewsletterForm()
  }

  constructor () {
    this.$form = $('#advanced_newsletter__form')
    this.privacyCheckbox = this.$form.find("[name='privacy_policy']")
    this.$errorAlert = this.$form.find('[data-role="error-alert"]')

    if (this.$form.length !== 0) {
      this.initValidation()

      if (!this.$form.find('.btn-captcha').length) {
        this.bindPrivacyCheckbox()
      } else {
        this.bindPrivacyCheckboxCaptcha()
      }

      if (this.privacyCheckbox.length > 0 && !this.privacyCheckbox[0].checked) {
        this.disabledSubmitButton()
      }
    }
  }

  initValidation () {
    this.$form.validate({
      submitHandler: (e) => { this.sendRequest() },
      invalidHandler: (event, validator) => {
        validator.showErrors()
      },
      rules: {
        email: {
          required: true,
          email: true
        }
      }
    })
  }

  disabledSubmitButton () {
    this.$form.find('[type="submit"]').attr('disabled', true).addClass('disabled')
  }
  enableSubmitButton () {
    this.$form.find('[type="submit"]').removeAttr('disabled').removeClass('disabled')
  }

  sendRequest () {
    this.onProgress()

    $.ajax({
      url: '/api/newsletter_subscriptions',
      method: 'POST',
      dataType: 'json',
      data: {
        newsletter_subscription: {
          email: this.extractEmail(),
          tags: this.extractCheckboxes(),
          first_name: this.extractNames('first_name'),
          last_name: this.extractNames('last_name'),
          phone: this.extractNames('phone'),
          company: this.extractNames('company'),
          custom_fields: this.extractCustomFields(),
          'h-captcha-response': this.extractCaptcha()
        }
      }
    })
      .done(() => {
        this.onSuccess()
      })
      .fail((error) => {
        this.onError(error)
        this.restoreForm()
      })
  }

  extractEmail () {
    const form = this.$form.serializeArray()
    const email = form.find(el => el.name === 'email')

    return email.value || email
  }

  extractCaptcha () {
    const form = this.$form.serializeArray()
    const captcha = form.find(el => el.name === 'h-captcha-response')

    return captcha ? captcha.value || captcha : ''
  }

  extractNames (filter) {
    const form = this.$form.serializeArray()

    const transformedNames = form.map(el => {
      const name = el.name.replace(/( )/g, '_').toLowerCase()

      if (['first_name', 'last_name', 'phone', 'company'].includes(name)) {
        return { type: name, value: el.value }
      }

      return {}
    }).find(el => el.type === filter)

    if (transformedNames) {
      return transformedNames.value || null
    }

    return null
  }

  extractCheckboxes () {
    return this.$form.serializeArray()
      .filter(el => el.value === 'checkbox-selected' && el.name)
      .map(el => el.name)
  }

  extractCustomFields () {
    const form = this.$form.serializeArray()

    const transformedCustomNames = {}

    form.forEach(el => {
      const name = el.name.replace(/( )/g, '_').toLowerCase()

      if (el.value && !DEFAULT_FIELDS.includes(name) && el.value !== 'checkbox-selected' && !name.match(/captcha-response/)) {
        transformedCustomNames[el.name] = el.value
      }
    })

    return transformedCustomNames
  }

  onSuccess () {
    $('.advanced_newsletter__title').addClass('d-none')
    $('.advanced_newsletter__success_icon').addClass('d-block')
    $('.advanced_newsletter__fields').addClass('d-none')
    $('.advanced_newsletter__flash_message').addClass('d-block')

    this.registerEventToGoogleAnalytics()
    this.onFormSubmitListener()
  }

  registerEventToGoogleAnalytics () {
    const formSelector = $(this.$form)

    if (window.gtag && formSelector.length > 0) {
      const eventAttr = $(formSelector[0]).data('newsletter-ga-attr')

      if (eventAttr) {
        const { category, action, label } = eventAttr
        window.gtag('event', action, {
          'event_category': category,
          'event_label': label
        })
      }
    }
  }

  onFormSubmitListener () {
    if (window.advancedNewsletterFormSubmit && typeof window.advancedNewsletterFormSubmit === 'function') {
      window.advancedNewsletterFormSubmit()
    }
  }

  bindPrivacyCheckbox () {
    this.privacyCheckbox.on('change', (e) => e.target.checked ? this.enableSubmitButton() : this.disabledSubmitButton())
  }

  bindPrivacyCheckboxCaptcha () {
    this.privacyCheckbox.on('change', (e) => {
      if (e.target.checked && this.$form.find('.captcha-solved').length) {
        this.enableSubmitButton()
      } else {
        this.disabledSubmitButton()
      }
    })
  }

  onProgress () {
    $('.advanced_newsletter__submit').attr('disabled', true)
    this.$errorAlert.hide()
  }

  restoreForm () {
    $('.advanced_newsletter__title').addClass('d-block')
    $('.advanced_newsletter__submit').attr('disabled', false)
    $('.advanced_newsletter__fields').addClass('d-block')
    $('.advanced_newsletter__success_icon').addClass('d-none')
    $('.advanced_newsletter__flash_message').addClass('d-none')
  }

  showErrorMessage (error) {
    this.$errorAlert.find('.error-message').text(error)
    this.$errorAlert.show()
    this.$errorAlert[0].scrollIntoView()
  }

  onError (error) {
    if (error.status === 422) {
      const errors = error.responseJSON['errors'].map((error) => {
        return error.detail
      }).join('<br>')
      console.error(errors)
      this.showErrorMessage(errors)
    } else {
      this.showErrorMessage(I18n.t('internal_server_error'))
    }
  }
}

export default AdvancedNewsletterForm
