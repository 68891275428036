class UserDefinedFilters {
  static async init () {
    if ($('#side-advanced-filters').length) return new UserDefinedFilters()
  }

  constructor () {
    const dataQuery = $.parseJSON($('code[data-query-params]').attr('data-query-params') || '{}')
    this.listingsPath = (dataQuery.path || 'listings') + '?'

    const cleanedDataQuery = dataQuery
    delete cleanedDataQuery['path']
    this.cleanedDataQuery = cleanedDataQuery

    this.bindEvents()
    this.initializeFields()
  }

  bindEvents () {
    var filtersEl = $('[data-role="user-defined-filter"]')
    filtersEl.on('click', 'button[role*="button-apply"]', $.proxy(this.onApplyClick, this))
    filtersEl.on('click', 'button[role*="button-clear"]', $.proxy(this.onClearClick, this))
    filtersEl.on('click', '.filters-group__header', $.proxy(this.onHeaderClick, this))
  }

  initializeFields () {
    var filtersEl = $('[data-role="user-defined-filter"]')

    filtersEl.each((index, filterEl) => {
      var $el = $(filterEl)
      if ($el.data('filterStatus') === 'opened') {
        var fieldName = $(filterEl).data('fieldName')
        this.loadField(fieldName, $(filterEl))
      }
    })
  }

  loadField (fieldName, root) {
    let params = Object.assign({ filters: [] }, this.cleanedDataQuery)
    params.field = fieldName

    let url = '/api/user_defined_filters?' + $.param(params)

    $.ajax({
      url: url,
      dataType: 'json'
    }).done((data) => {
      root.html(data.html)
    }).fail((error) => console.log(error))
  }

  onHeaderClick (e) {
    var fieldName = $(e.currentTarget).parent().data('fieldName')
    var paramName = $(e.currentTarget).parent().data('paramName')
    const currentForm = $(document).find('#body_spec_' + paramName)

    $(e.currentTarget).find('.fa-chevron-up, .fa-chevron-down').toggleClass('fa-chevron-up fa-chevron-down')
    $(e.currentTarget).find('.fa-plus, .fa-minus').toggleClass('fa-plus fa-minus')

    if (currentForm.length === 0) {
      $(e.currentTarget).parent().attr('id', 'filter_spec' + paramName)

      this.loadField(fieldName, $(e.currentTarget).parent())
    } else if (currentForm.hasClass('hidden')) {
      currentForm.removeClass('hidden')
    } else {
      currentForm.addClass('hidden')
    }
  }

  onApplyClick (e) {
    e.preventDefault()

    let formId = $(e.target).data('form-id')
    let fieldName = $(e.target).data('field')

    let serializedArray = $('form#body_spec_' + formId).serializeArray()
    let params = Object.assign({ filters: [] }, this.cleanedDataQuery)

    params.filters = params.filters.filter((param) => {
      return param.field !== fieldName
    })

    serializedArray.forEach((formField) => {
      params.filters.push({
        field: fieldName,
        operation: formField.name,
        value: formField.value
      })
    })

    window.location.href = this.listingsPath + $.param(params)
  }

  onClearClick (e) {
    e.preventDefault()

    let fieldName = $(e.target).data('field')
    let params = Object.assign({ filters: [] }, this.cleanedDataQuery)

    params.filters = params.filters.filter((param) => {
      return param.field !== fieldName
    })

    window.location.href = this.listingsPath + $.param(params)
  }
}

export default UserDefinedFilters
