class ListingFiltersToggle {
  static async init () {
    const $listingFiltersToggleButton = $('#filters-toggle-button')
    const $listingFilters = $('.listing-filters, .advanced-filters')
    $listingFiltersToggleButton.on('click', (e) => {
      e.preventDefault()
      $listingFiltersToggleButton.toggleClass('clicked').find('> .fa').toggleClass('fa-filter fa-times fa-solid')
      $listingFilters.toggleClass('open')
    })
  }
}

export default ListingFiltersToggle
