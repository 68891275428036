import Choices from 'choices.js'

const CATEGORY_SELECT_SELECTOR = '[data-role="js-category-select"]'
class CategorySelect {
  static async init () {
    const categorySelects = document.querySelectorAll(CATEGORY_SELECT_SELECTOR)

    if (categorySelects.length === 0) return

    categorySelects.forEach(categorySelect => {
      const choices = new Choices(categorySelect, {
        shouldSort: false,
        searchEnabled: true,
        itemSelectText: '',
        callbackOnCreateTemplates: function (template) {
          return {
            choice: (classes, attr) => {
              const el = Choices.defaults.templates.choice.call(this, classes, attr)
              if (attr.element.className) {
                el.classList.add(attr.element.className)
              }

              return el
            }
          }
        }
      })

      categorySelect.addEventListener('change', (event) => {
        const selectedOption = event.target.selectedOptions[0]
        if (selectedOption) {
          const path = selectedOption.dataset.path
          if (path) {
            window.location.href = path
          }
        }
      })

      categorySelect.querySelectorAll('option').forEach((option) => {
        if (option.className) {
          const choicesValue = choices.getValue(true)
          let selectedValues = []

          if (choicesValue) {
            if (Array.isArray(choicesValue)) {
              selectedValues = choicesValue
            } else {
              selectedValues = [choicesValue]
            }
          }

          const item = selectedValues.find(item => item?.value === option.value)

          if (item) {
            item.classList.add(option.className)
          }
        }
      })

      if (document.querySelector('.default-search-bar > .notranslate, .trial-search-bar > .notranslate')) {
        categorySelect.classList.add('notranslate')
      }
    })
  }
}

export default CategorySelect
