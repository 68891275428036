import CurrencyBaseWidget from './currencies_base_widget'

class CurrenciesDropdown extends CurrencyBaseWidget {
  static async init () {
    return new CurrenciesDropdown()
  }

  constructor () {
    super('.currencies .dropdown-menu a')

    const $selectedElem = this.$currencyLinks.siblings(`[data-currency='${this.selectedCurrency()}']`)

    if ($selectedElem[0]) {
      $('#currency_dropdown').html($selectedElem[0].innerHTML)
    }
  }
}

export default CurrenciesDropdown
