import Cookies from 'js-cookie'

const COOKIE_CATEGORY = 'analytics'

class Track {
  static async init () {
    return new Track()
  }

  constructor () {
    this.VISITOR_KEY = 'mhvid'
    this.timeout = 5000
    this.endpointPath = '/collect'
  }

  trackView (data) {
    this.send('pageview', data)
  }

  send (eventType, data = {}) {
    if (!Cookies.get(this.VISITOR_KEY)) { return }

    const payload = this.buildPayload(eventType, data)

    if (this.isBeaconSupported()) {
      try {
        this.sendWithBeacon(payload)
      } catch (error) {
        console.warn(error)
      }
    } else {
      this.sendWithJQuery(payload)
    }
  }

  isBeaconSupported () {
    return 'sendBeacon' in navigator
  }

  sendWithJQuery (payload) {
    $.post(this.endpointPath, payload).catch(e => console.error(e))
  }

  sendWithBeacon (payload) {
    navigator.sendBeacon(this.endpointPath, JSON.stringify(payload))
  }

  buildPayload (eventType, data) {
    data.event_type = eventType
    data._t = new Date().getTime()
    data.referrer = window.document.referrer
    data.url = window.location.href
    data.visitor_id = Cookies.get(this.VISITOR_KEY)

    return data
  }
}

export default Track
