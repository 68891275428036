/* global I18n */

import BaseForm from './base_form'
import Track from 'tracking_events/scripts/track'
import QueryString from 'utils/query_string'

class LeadForm extends BaseForm {
  static init (listingId, formSelector) {
    return new LeadForm(listingId, formSelector)
  }

  constructor (listingId, formSelector) {
    super(formSelector)

    this.listingId = listingId
  }
  onSubmit () {
    this.disabledSubmitButton()

    $.ajax({
      url: '/api/leads',
      method: 'POST',
      dataType: 'json',
      data: {
        listing_id: this.listingId,
        lead: this.formData(),
        utm_params: JSON.parse(sessionStorage.getItem('utm_params') || '{}'),
        gclid_params: QueryString.parseGclidParams(window.location.search)
      }
    }).done((response) => {
      const leadId = response.data.id
      this.trackLeadSubmit(leadId)
      this.onSuccess(I18n.t('thank_you_for_your_request'))
    }).fail((error) => {
      this.onError(error)
    }).always(() => {
      this.enableSubmitButton()
    })
  }

  trackLeadSubmit (leadId) {
    if (window.onLeadSubmitted && typeof window.onLeadSubmitted === 'function') {
      window.onLeadSubmitted({
        listing_id: this.listingId,
        lead_id: leadId
      })
    }

    new Track().send('event', {
      event_category: 'lead_submit',
      event_value: leadId,
      listing_ids: [this.listingId]
    })
  }

  onSuccess () {
    if (window.gtag) {
      window.gtag('event', 'submit', {
        'event_category': 'Leads',
        'event_label': 'click'
      })
    }

    if (window.gtagReportConversion) {
      window.gtagReportConversion()
    }

    if (window.fbq) {
      window.fbq('track', 'Lead')
    }

    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'gtm.formSubmit',
      fields: { listing_id: this.listingId, ...this.formData() },
      formID: this.$form[0].id
    })

    super.onSuccess()
  }
}

export default LeadForm
