import RequestQuoteForm from 'forms/scripts/request_quote_form'
import LeadModal from 'modals/scripts/lead_modal'

class RequestQuoteModal extends LeadModal {
  static init (listingId) {
    return new RequestQuoteModal(listingId)
  }

  initForm () {
    this.form = new RequestQuoteForm(this.listingId)

    this.show()
  }
}

export default RequestQuoteModal
