/* global I18n */

class SortingMore {
  static render ($parent, options) {
    return new SortingMore($parent, options)
  }

  constructor ($parent, options) {
    this.$parent = $parent
    this.options = options

    this.render()

    this.$form = $('#modal_sorting_more_form')

    const dataQuery = jQuery.parseJSON($('code[data-query-params]').attr('data-query-params') || '{}')
    const listingPath = dataQuery['path'] || '/listings'

    const cleanedDataQuery = dataQuery
    delete cleanedDataQuery['path']

    this.$form.validate({
      submitHandler: () => {
        const routePath = listingPath + '?' + $.param({ ...cleanedDataQuery, ...this.formData() })

        window.location.href = routePath
      }
    })
  }

  formData () {
    return this.$form.serializeArray().reduce((acc, item) => {
      acc[item.name] = item.value
      return acc
    }, {})
  }

  render () {
    this.$parent.html(this.template).ready(() => {
      this.$element = this.$parent.children().first()
    })
  }

  get template () {
    if (this.options.length > 0) {
      return `
        <div class="text-center">
          <h5>
            ${I18n.t('sort_by')}
          </h5>
          <form class="" id="modal_sorting_more_form" method="get" action=${this.actionPath} >
            <select name="sort_by" class="form-control my-2">
              ${this.options.map((option) => `
                <option class="notranslate">${option}</option>`).join('')}
            </select>
            <select name="sort_by_direction" class="form-control">
              <option value="asc">${I18n.t('sorting.asc')}</option>
              <option value="desc">${I18n.t('sorting.desc')}</option>
            </select>
            <button id="sorting-more-submit-btn" class="w-100 mt-3 btn btn-primary" type="submit">
              ${I18n.t('sort')}
          </form>
        </div>
      `
    } else {
      return `
        <div class="text-center">
          <h5>
            ${I18n.t('specifications_not_found')}
          </h5>
        </div>
      `
    }
  }
}

export default SortingMore
