/* global I18n */

const DEFAULT_FIELDS = ['first_name', 'last_name', 'phone', 'company', 'email', 'address', 'city', 'region', 'postal_code', 'country']

class NewsletterSubscription {
  static render ($parent, captchaHtml) {
    return new NewsletterSubscription($parent, captchaHtml)
  }

  constructor ($parent, captchaHtml) {
    this.$parent = $parent
    this.captchaHtml = captchaHtml

    this.render()

    this.$form = $('#modal_newsletter_form')

    if (this.$form.length !== 0) {
      this.initValidation()
    }
  }

  initValidation () {
    this.$form.validate({
      submitHandler: () => { this.sendRequest() },
      rules: {
        email: {
          required: true,
          email: true
        },
        ...this.otherRequiredFields()
      },
      messages: {
        first_name: {
          required: I18n.t('validation.first_name')
        },
        last_name: {
          required: I18n.t('validation.last_name')
        },
        email: {
          required: I18n.t('validation.email'),
          email: I18n.t('validation.email')
        }
      }
    })
  }

  sendRequest () {
    $.ajax({
      url: '/api/newsletter_subscriptions',
      method: 'POST',
      dataType: 'json',
      data: {
        newsletter_subscription: {
          ...this.formData(),
          custom_fields: this.extractCustomFields()
        }
      }
    }).done(this.disableForm)
      .fail((error) => this.onError(error))
  }

  onError (error) {
    if (error.status === 422) {
      const errors = error.responseJSON['errors'].map((error) => {
        return error.detail
      }).join('<br>')
      console.error(errors)
      this.showErrorMessage(errors)
    } else {
      this.showErrorMessage(I18n.t('internal_server_error'))
    }
  }

  showErrorMessage (error) {
    this.$errorAlert = this.$form.find('[data-role="error-alert"]')
    this.$errorAlert.find('.error-message').text(error)
    this.$errorAlert.show()
    this.$errorAlert[0].scrollIntoView()
  }

  extractCustomFields () {
    const form = this.$form.serializeArray()

    const transformedCustomNames = {}

    form.forEach(el => {
      const name = el.name.replace(/( )/g, '_').toLowerCase()

      if (el.value && !DEFAULT_FIELDS.includes(name) && el.value !== 'checkbox-selected' && !name.match(/captcha-response/)) {
        transformedCustomNames[el.name] = el.value
      }
    })

    return transformedCustomNames
  }

  formData () {
    return this.$form.serializeArray().reduce((acc, item) => {
      acc[item.name] = item.value
      return acc
    }, {})
  }

  disableForm () {
    $('.successful-submission').text(I18n.t('thank_you_for_subscription')).addClass('text-success')
    $('#modal_newsletter_form input').prop('disabled', true)
    $('#newsletter-signup-btn').prop('disabled', true)
    $('#modal_newsletter_form').find('[data-role="error-alert"]').hide()
  }

  render () {
    this.$parent.html(this.template).ready(() => {
      this.$element = this.$parent.children().first()
    })
  }

  otherRequiredFields () {
    const fields = {
      first_name: {
        required: true
      },
      last_name: {
        required: true
      }
    }

    if (window.customerCustomFields && window.customerCustomFields.length > 0) {
      window.customerCustomFields.forEach(field => {
        fields[field.name] = {
          required: field.required
        }
      })
    }

    return fields
  }

  customerCustomFields () {
    if (window.customerCustomFields && window.customerCustomFields.length > 0) {
      return window.customerCustomFields
    }

    return []
  }

  renderCustomFields () {
    return this.customerCustomFields().map(field => {
      const label = field.name.toLowerCase().replace(/\s/g, '_')

      return `
        <div class="form-group col-12 px-0 text-left">
          <label for="${label}">
            ${field.name}
            ${field.required === true ? '<span class="notranslate text-danger">*<span/>' : ''}
          </label>
          <input type="input" id="${label}" name="${label}" class="form-control">
          </input>
        </div>
      `
    }).join('')
  }
  get template () {
    if ($('.js-newsletter-subscription-btn').data('role') === 'extended') {
      return `
        <div class="text-center">
          <h5 class="successful-submission mb-3"></h5>
          <form class="" id="modal_newsletter_form" method="post" action="/api/newsletter_subscriptions">
            <div class="alert alert-danger" data-role="error-alert" style="display: none;">
              <div class="error-message">
              </div>
            </div>
            <div class="form-group col-12 px-0 text-left">
              <label for="first_name">${I18n.t('first_name')} <span class="notranslate text-danger">*<span/></label>
              <input id="first_name" type="input" name="first_name" class="form-control">
              </input>
            </div>
            <div class="form-group col-12 px-0 text-left">
              <label for="last_name">${I18n.t('last_name')} <span class="notranslate text-danger">*<span/></label>
              <input id="last_name" type="input" name="last_name" class="form-control">
              </input>
            </div>
            ${this.renderCustomFields()}
            <div class="form-group col-12 px-0 text-left">
              <label for="email">${I18n.t('email')} <span class="notranslate text-danger">*<span/></label>
              <input id="email" type="email" name="email" class="form-control">
              </input>
            </div>
            ${this.captchaHtml || ''}
            <button id="newsletter-signup-btn" class="newsletter__button w-100 mt-3 btn btn-primary ${this.captchaHtml ? 'btn-captcha' : ''}" ${this.captchaHtml ? 'disabled' : ''} type="submit">
              ${I18n.t('sign_up')}
            </button>
          </form>
        </div>
      `
    } else {
      return `
        <div class="text-center">
          <h5 class="successful-submission mb-3"></h5>
          <form class="" id="modal_newsletter_form" method="post" action="/api/newsletter_subscriptions">
            <div class="alert alert-danger" data-role="error-alert" style="display: none;">
              <div class="error-message">
              </div>
            </div>
            <div class="form-group col-12 px-0 text-left">
              <label for="email">${I18n.t('email')} <span class="notranslate text-danger">*<span/></label>
              <input id="email" type="email" name="email" class="form-control">
              </input>
            </div>
            ${this.renderCustomFields()}
            ${this.captchaHtml || ''}
            <button id="newsletter-signup-btn" class="newsletter__button w-100 mt-3 btn btn-primary ${this.captchaHtml ? 'btn-captcha' : ''}" ${this.captchaHtml ? 'disabled' : ''} type="submit">
              ${I18n.t('sign_up')}
            </button>
          </form>
        </div>
      `
    }
  }
}

export default NewsletterSubscription
