import Track from 'tracking_events/scripts/track'

class ListingEvents {
  static init () {
    const $container = $('.listing-show')

    if ($container.length > 0) {
      const listingId = $container.data('listingId')

      new Track().trackView({
        event_category: 'listing',
        listing_ids: [listingId]
      })
    }
  }
}

export default ListingEvents
