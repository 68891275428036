/* global I18n */

class NewsletterSubscriptionForm {
  static async init () {
    return new NewsletterSubscriptionForm()
  }

  constructor () {
    this.$forms = $('[data-role="newsletter_form"]')

    if (this.$forms.length !== 0) {
      this.initValidation()
    }
  }

  initValidation () {
    const self = this

    this.$forms.each(function (index) {
      $(this).validate({
        submitHandler: () => {
          const dataInfo = $(this).serializeArray().reduce((acc, item) => {
            acc[item.name] = item.value
            return acc
          }, {})

          self.sendRequest(dataInfo, index)
        },
        invalidHandler: (event, validator) => {
          validator.showErrors()

          if ($('.newsletter_form__inner_wrapper .input-group #email-error')) {
            $('#email-error').appendTo($(this).find('.newsletter_form__inner_wrapper'))
          }

          if ($('.site-footer__widget #email-error')) {
            $('#email-error').appendTo($(this).find('.newsletter_error_message'))
          }
        },
        rules: {
          email: {
            required: true,
            email: true
          }
        }
      })
    })
  }

  sendRequest (dataInfo, index) {
    $.ajax({
      url: '/api/newsletter_subscriptions',
      method: 'POST',
      dataType: 'json',
      data: {
        newsletter_subscription: {
          ...dataInfo
        }
      }
    })
      .done(() => {
        this.disableForm(index)
      })
      .fail((error) => this.onError(error, index))
  }

  onError (error, index) {
    if (error.status === 422) {
      const errors = error.responseJSON['errors'].map((error) => {
        return error.detail
      }).join('<br>')
      console.error(errors)
      this.showErrorMessage(errors, index)
    } else {
      this.showErrorMessage(I18n.t('internal_server_error'), index)
    }
  }

  showErrorMessage (error, index) {
    const form = $($('[data-role="newsletter_form"]')[index])
    this.$errorAlert = form.find('[data-role="error-alert"]')
    this.$errorAlert.find('.error-message').text(error)
    this.$errorAlert.show()
    this.$errorAlert[0].scrollIntoView()
  }

  disableForm (index) {
    const formSelector = $(this.$forms)

    if (window.gtag && formSelector.length > 0) {
      const eventAttr = $(formSelector[0]).data('newsletter-ga-attr')

      if (eventAttr) {
        const { category, action, label } = eventAttr
        window.gtag('event', action, {
          'event_category': category,
          'event_label': label
        })
      }
    }

    const submittedForm = $($('[data-role="newsletter_form"]')[index])
    submittedForm.find('.newsletter__title').text(I18n.t('thank_you_for_subscription')).addClass('filled')
    submittedForm.find('[data-role="error-alert"]').hide()

    $('[data-role="newsletter_form"]').addClass('submitted')
    $('.newsletter__input').prop('disabled', true)
    $('.newsletter__button').prop('disabled', true)
  }
}
export default NewsletterSubscriptionForm
