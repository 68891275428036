import LeadForm from 'forms/scripts/lead_form'

const FORM_SELECTOR = '#inline_lead_form'

class InlineLeadForm {
  static async init () {
    const listingId = $(FORM_SELECTOR).data('listing-id')

    if (listingId) {
      LeadForm.init(listingId, FORM_SELECTOR)
    }
  }
}

export default InlineLeadForm
