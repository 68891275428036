import BaseForm from './base_form'
import QueryString from 'utils/query_string'

const FORM_SELECTOR = '[data-role="direct_request_form"]'

class DirectRequestForm extends BaseForm {
  static async init () {
    return new DirectRequestForm()
  }
  constructor () {
    super(FORM_SELECTOR)
  }
  onSubmit () {
    this.disabledSubmitButton()

    $.ajax({
      url: '/api/direct_requests',
      method: 'POST',
      dataType: 'json',
      data: {
        seller_id: this.$form.data('seller-id'),
        direct_request: {
          source: document.location.href,
          utm_params: JSON.parse(sessionStorage.getItem('utm_params') || '{}'),
          ...this.formData()
        }
      }
    }).done((result) => {
      this.onSuccess(result.data)
    }).fail((error) => {
      this.onError(error)
    }).always(() => {
      this.enableSubmitButton()
    })
  }

  messageBody () {
    const { message } = this.formData()

    return message
  }

  onSuccess (data) {
    super.onSuccess()

    this.trackEvent()

    if (window.gtagReportConversion) {
      window.gtagReportConversion()
    }

    if (this.messageBody().length) {
      const message = { id: data.id, ...data.attributes }
      const chatMessages = JSON.parse(localStorage.getItem('chat-messages') || '[]')
      chatMessages.push({ id: message.id, message: this.messageBody(), type: 'customer' })
      localStorage.setItem('chat-messages', JSON.stringify(chatMessages))
    }
  }

  trackEvent () {
    if (window.gtag && ('enable_analytics' in this.formData())) {
      const formSelector = $(FORM_SELECTOR)

      if (formSelector.length > 0) {
        const contactFormAttribute = $(formSelector[0]).data('contact-form-ga-attribute')

        if (contactFormAttribute) {
          const { category, action, label } = contactFormAttribute

          this.invokeGoogleAnalyticsSnippet(category, action, label)
        } else {
          this.invokeGoogleAnalyticsSnippet('contact-form', 'submit', 'click')
        }
      } else {
        this.invokeGoogleAnalyticsSnippet('contact-form', 'submit', 'click')
      }
    }

    if (window.msysOnDirectRequetFormSubmitted && typeof window.msysOnDirectRequetFormSubmitted === 'function') {
      window.msysOnDirectRequetFormSubmitted()
    }
  }

  invokeGoogleAnalyticsSnippet (category, action, label) {
    window.gtag('event', action, {
      'event_category': category,
      'event_label': label
    })
  }
}

export default DirectRequestForm
