class ContactEvent {
  static async init () {
    $('.contact-tracking').click((event) => {
      if (window.fbq) {
        window.fbq('track', 'Contact')
      }

      if (window.gtag) {
        const phoneLinkGAAttribute = $(event.target).data('phone-ga-atrr') || {}

        const eventRequiredFields = ['category', 'action']

        const isValid = eventRequiredFields.map(el => {
          return phoneLinkGAAttribute.hasOwnProperty(el) && phoneLinkGAAttribute[el]
        }).every(el => el)

        if (isValid) {
          const { category, action, label } = phoneLinkGAAttribute

          window.gtag('event', action, {
            'event_category': category,
            'event_label': label
          })
        } else {
          window.gtag('event', 'click-on-contact', {
            'event_category': 'Contact',
            'event_label': 'click'
          })
        }
      }
    })
  }
}

export default ContactEvent
