class TabsScrolling {
  static async init () {
    const $navTabs = $('.nav-tabs')
    if ($navTabs.attr('data-scroll-on-click') === 'on' && $navTabs.attr('data-scroll-target') !== '') {
      const targetOffsetTop = $($navTabs.attr('data-scroll-target')).offset().top
      $('.tab_link', $navTabs).on('click', () => {
        $('html, body').animate({
          scrollTop: targetOffsetTop
        }, 300)
      })
    }
  }
}

export default TabsScrolling
