class QueryString {
  static parse (queryString) {
    const params = {}

    queryString.substr(1).split('&').forEach(pair => {
      let key, value
      [key, value] = pair.split('=')
      params[key] = value
    })

    return params
  }

  static parseUtmParams (queryString) {
    const utmParams = {}
    const searchParams = this.parse(decodeURIComponent(queryString.replace(/\+/g, '%20')))

    Object.entries(searchParams).forEach((pair) => {
      if (pair[0].startsWith('utm_')) {
        utmParams[pair[0]] = pair[1]
      }
    })

    return utmParams
  }

  static parseGclidParams (queryString) {
    const utmParams = {}
    const searchParams = this.parse(decodeURIComponent(queryString.replace(/\+/g, '%20')))

    Object.entries(searchParams).forEach((pair) => {
      if (pair[0].startsWith('gclid')) {
        utmParams[pair[0]] = pair[1]
      }
    })

    return utmParams
  }
}

export default QueryString
