import LeadForm from 'forms/scripts/lead_form'

const FORM_SELECTOR = '#lead_form'

class LeadModal {
  static init (listingId) {
    return new LeadModal(listingId)
  }
  constructor (listingId) {
    this.listingId = listingId
    this.$modal = this.initModal()

    this.loadForm()
    this.bindCallbacks()
  }
  show () {
    this.$modal.modal('show')

    if (window.msysOnLeadFormModalShow && typeof window.msysOnLeadFormModalShow === 'function') {
      window.msysOnLeadFormModalShow()
    }
  }
  initForm () {
    this.form = new LeadForm(this.listingId, FORM_SELECTOR)

    this.show()
  }
  loadForm () {
    if (this.$modal.find('form').length > 0) {
      this.initForm()
    } else {
      $.getJSON('/api/leads/new', { listing_id: this.listingId }, (json) => {
        this.$modal.find('.modal-body').html(json.html)
        this.initForm()
      })
    }
  }
  bindCallbacks () {
    this.$modal.on('hidden.bs.modal', (event) => { this.form.reset() })
  }
  initModal () {
    let $modal = $('#lead_modal')

    if ($modal.length === 0 || $modal.attr('data-listing-id')) {
      if ($modal.length) { $modal.remove() }
      $modal = $(this.template)
      $modal.appendTo(document.body)
    }

    return $modal
  }
  get template () {
    return `
      <div aria-hidden="true" class="modal fade" id="lead_modal" role="dialog" data-listing-id="${this.listingId}" tabindex="-1">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button aria-label="Close" class="close" data-dismiss="modal" type="button"><span aria-hidden="true"> ×</span></button>
                </div>
                <div class="modal-body"></div>
            </div>
        </div>
      </div>`
  }
}

export default LeadModal
