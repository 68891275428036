import Track from 'tracking_events/scripts/track'

class SearchResultsEvents {
  static init () {
    if ($('.listings-index').length > 0) {
      return new SearchResultsEvents()
    }
  }

  constructor () {
    this.$container = $('.listings-index')
    this.listing_ids = this.getListingIds()
    this.unloaded = false
    this.track = new Track()
    this.track.trackView({
      event_category: 'search_results',
      listing_ids: this.listing_ids
    })

    this.bindEvents()
  }

  bindEvents () {
    this.$container.on('click', '[data-role=show-listing]', e => this.onListingClick(e))
  }

  onListingClick (e) {
    e.preventDefault()

    this.trackOnPageUnload(e)

    window.location = $(e.currentTarget).attr('href')
  }

  trackOnPageUnload (e) {
    window.addEventListener('pagehide', this.trackListingClick(e))
    window.addEventListener('unload', this.trackListingClick(e))
  }

  trackListingClick (e) {
    if (this.unloaded) return
    this.unloaded = true

    const $el = $(e.currentTarget)
    const listingId = $el.data('id')
    const eventAction = $el.data('eventAction')

    const eventData = {
      event_category: 'listing_click',
      event_action: eventAction,
      event_value: this.listing_ids.indexOf(listingId),
      listing_ids: [listingId]
    }

    this.track.send('event', eventData)
  }

  getListingIds () {
    return this.$container.find('[data-role=show-listing]').toArray().reduce((acc, i) => {
      let id = parseInt($(i).data('id'))
      if (acc.indexOf(id) === -1) { acc.push(id) }
      return acc
    }, [])
  }
}

export default SearchResultsEvents
