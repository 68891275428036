/* global I18n */

import BaseForm from './base_form'
import Track from 'tracking_events/scripts/track'

class EmailListingForm extends BaseForm {
  static init ($form) {
    return new EmailListingForm($form)
  }

  static render ($parent, { listingId, messageField, emailLang }) {
    if ($parent.find('form').length === 0) {
      let field = ''
      if (messageField) {
        field = `
          <div class="message">
            <label for="message">${I18n.t('message')}</label>
            <textarea type="text" name="message" id="message" value="" class="form-control"></textarea>
          </div>
          </br>`
      }

      const $template = $(`
        <div>
          <form data-listing-id="${listingId}" data-email-lang="${emailLang}" id="send_listing_email">
            <div class="form-group">
              <label for="Email">${I18n.t('email')}</label>
              <input type="text" name="email" id="email" value="" class="form-control">
            </div>
            ${field}
            <div class="form-group">
              <button name="button" type="submit" class="btn btn-primary col-12">${I18n.t('send')}</button>
            </div>
          </form>
          <div class="${'hidden text-center'.concat(I18n.has('successful_email') ? ' notranslate' : '')}" id="successful_form_submission">${I18n.t('successful_email')}</div>
        </div>
      `)

      $parent.append($template)
      const $form = $template.find('form')

      EmailListingForm.init($form)

      return $form
    } else {
      return $parent.find('form')
    }
  }

  constructor ($form) {
    super('#' + $form[0].id)
    this.listingId = this.$form.data('listing-id')
    this.emailLang = this.$form.data('email-lang')
  }

  onSubmit () {
    this.disabledSubmitButton()

    $.ajax({
      url: `/api/listings/${this.listingId}/emails`,
      method: 'POST',
      dataType: 'json',
      data: {
        ...this.formData(),
        lang: this.emailLang
      }
    }).done(() => {
      this.onSuccess()
      this.trackEmailListingSubmit()
    }).fail((error) => {
      this.onError(error)
    }).always(() => {
      this.enableSubmitButton()
    })
  }

  initValidation () {
    this.$form.validate({
      submitHandler: () => { this.onSubmit() },
      rules: {
        email: {
          required: true,
          email: true
        },
        message: {
          required: false
        }
      },
      messages: {
        email: {
          required: I18n.t('email'),
          email: I18n.t('email')
        }
      }
    })
  }

  trackEmailListingSubmit () {
    new Track().send('event', {
      event_category: 'send_email_submit'
    })
  }
}

export default EmailListingForm
