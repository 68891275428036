import Track from 'tracking_events/scripts/track'
import WebsiteConfigs from 'website_configs/website_configs'

class PrintButtons {
  static async init () {
    $('[data-role="js-print"]').on('click', () => {
      const src = `${window.location.pathname}/print`

      new Track().send('event', {
        event_category: 'print_listing_click',
        event_action: 'show'
      })

      handlePrint(src, 'print-listing')
    })

    $('[data-role="js-print-listings"]').on('click', (e) => {
      const src = $(e.currentTarget).data('path')

      new Track().send('event', {
        event_category: 'print_listing_click',
        event_action: 'index'
      })

      handlePrint(src, 'print-listings')
    })

    $('[data-role="native-print"]').on('click', () => {
      new Track().send('event', {
        event_category: 'native_print_click'
      })

      window.print()
    })

    function handlePrint (src, name) {
      if (WebsiteConfigs.automatedLocale) {
        window.print()
      } else {
        $(`iframe[name="${name}"]`).remove()
        $('<iframe>').attr({ src, name }).hide().appendTo('body')
        $(window.frames[name]).on('load', function () {
          this.print()
        })
      }
    }
  }
}

export default PrintButtons
