import Cookies from 'js-cookie'
import WebsiteConfigs from 'website_configs/website_configs'

const COOKIE_CATEGORY = 'functional'

class SortingSelect {
  static async init () {
    const dataQuery = jQuery.parseJSON($('code[data-query-params]').attr('data-query-params') || '{}')
    const listingsPath = (dataQuery.path || 'listings') + '?'
    const cleanedDataQuery = dataQuery
    delete cleanedDataQuery['path']

    const customSortingDropdown = $('.custom-sorting-dropdown')

    $('.select-sort-by').click((event) => {
      event.preventDefault()

      const node = event.target.tagName === 'BUTTON' ? event.target : event.currentTarget

      const sortBy = node.dataset['sort_by']
      const direction = node.dataset['direction']

      if (!customSortingDropdown.length && !(window.orejime && window.orejime.internals.manager.consents[COOKIE_CATEGORY] !== true)) {
        Cookies.set('sort_by', sortBy, { expires: 30, path: '/' })
        Cookies.set('sort_by_direction', direction, { expires: 30, path: '/' })
      }

      if (location.pathname === '/') {
        location.replace(node.getAttribute('data-href'))
      } else if (customSortingDropdown.length) {
        location.replace(listingsPath + $.param({ ...cleanedDataQuery, sort_by: sortBy, sort_by_direction: direction }))
      } else {
        if (location.search) {
          delete cleanedDataQuery['sort_by']
          delete cleanedDataQuery['sort_by_direction']
          location.replace(listingsPath + $.param(cleanedDataQuery))
        } else {
          location.reload()
        }
      }
    })

    customSortingDropdown.on('show.bs.dropdown', (e) => {
      const directions = { asc: ' <i class="fa fa-arrow-up"></i>', desc: ' <i class="fa fa-arrow-down"></i>' }

      if (!$(e.currentTarget).hasClass('updated')) {
        $.ajax({
          url: '/api/top_specification_fields/',
          dataType: 'json',
          data: cleanedDataQuery
        }).done((data) => {
          $.each(data.fields.reverse(), (id, option) => {
            $.each(directions, (direction, directionArrow) => {
              if (!data.show_sorting_directions && direction !== 'desc') {
                return
              } else if (!data.show_sorting_directions) {
                directionArrow = ''
              }

              let linkStatus = ''
              if (option === dataQuery['sort_by'] && direction === dataQuery['sort_by_direction']) {
                linkStatus = 'active'
              }

              let targetHref = listingsPath + $.param({ ...cleanedDataQuery, sort_by: option, sort_by_direction: direction })
              let capitalizedOption = option.charAt(0).toUpperCase() + option.slice(1)

              $(e.currentTarget).find('.dropdown-menu').prepend(`<a class="dropdown-item ${linkStatus}" href=${targetHref}><span class="${WebsiteConfigs.automatedLocale ? '' : 'notranslate'}">${capitalizedOption}</span>${directionArrow}</a>`)
            })
          })

          $(e.currentTarget).addClass('updated')
        }).fail((error) => console.log(error))
      }
    })
  }
}
export default SortingSelect
