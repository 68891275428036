/* global I18n */

import Currency from 'utils/currency'
import WebsiteConfigs from 'website_configs/website_configs'

const PRICES_SELECTOR = '.listing-price-data'
const COUNTRY_ISO_CODE_KEY = 'country-iso-code'
const TARGET_CURRENCY_CODE_KEY = 'target-currency-code'
const RATE_KEY = 'rate'

class PriceConverting {
  static async init () {
    return new PriceConverting()
  }

  constructor () {
    if (WebsiteConfigs.convertPrices && $(PRICES_SELECTOR).length > 0) {
      if (sessionStorage.getItem(RATE_KEY)) {
        this.convertPrices()
      } else {
        this.getCurrencyRateAndConvertPrices()
      }
    }
  }

  getCurrencyRateAndConvertPrices () {
    $.ajax({
      url: '/api/currency_exchange_rates',
      dataType: 'json',
      data: { country: sessionStorage.getItem(COUNTRY_ISO_CODE_KEY) }
    }).done((data) => {
      sessionStorage.setItem(RATE_KEY, data.rate || 1)
      sessionStorage.setItem(TARGET_CURRENCY_CODE_KEY, data.target_currency_code || WebsiteConfigs.currency)

      this.convertPrices()
    }).fail((error) => console.log(error))
  }

  convertPrices () {
    const rate = sessionStorage.getItem(RATE_KEY)
    const targetCurrencyCode = sessionStorage.getItem(TARGET_CURRENCY_CODE_KEY)

    if (rate && targetCurrencyCode !== WebsiteConfigs.currency) {
      $(PRICES_SELECTOR).each((_index, price) => {
        let priceItem = $(price)
        let currentPrice = priceItem.data('listing-price')
        let roundPrice

        if (currentPrice > 0) {
          if (targetCurrencyCode === 'jpy') {
            roundPrice = Math.round(currentPrice * rate).toLocaleString(I18n.lang)
          } else {
            roundPrice = (currentPrice * rate).toLocaleString(I18n.lang, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
          }

          let convertedPrice = Currency.currencySymbol(targetCurrencyCode).replace('$', '$$$$') + roundPrice + ' (' + targetCurrencyCode.toUpperCase() + ')'
          let priceText = priceItem.text()

          priceItem.text(priceText.replace(/^.*?\d+(?:(\s|\.|,)\d{3})*(?:(\.|,)\d{2})?(?:\s\(\w{3}\))?/, convertedPrice))
        }
      })
    }
  }
}
export default PriceConverting
