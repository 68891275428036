const SEARCH_INPUT_SELECTOR = '[data-role="searchbar"]'

class SearchAutocomplete {
  static async init () {
    const $forms = $(SEARCH_INPUT_SELECTOR)

    if (!$forms[0]) return

    $forms.each((_index, form) => {
      let $form = $(form)
      let separatedCategory = $form.data('separated-category')
      let searchCategory = $form.data('search-category')

      $form.autocomplete({
        minLength: 2,
        appendTo: '.autocomplete-wrapper',
        delay: 500,
        source (request, response) {
          $.getJSON('/dict/search_autocomplete.json',
            { search_term: request['term'],
              search_category: searchCategory,
              separated_category: separatedCategory },
            (words) => {
              response(words)
            }
          )
        },
        select (event, ui) {
          const path = ($form.data('autocomplete-select-path') || '/listings') + '?q='

          window.location.href = path + encodeURIComponent(ui.item.label)
        }
      })
    })
  }
}

export default SearchAutocomplete
