/* global I18n */

import BaseForm from './base_form'
import Track from 'tracking_events/scripts/track'

const FORM_SELECTOR = '#lead_form'

class RequestQuoteForm extends BaseForm {
  static init (listingId) {
    return new RequestQuoteForm(listingId)
  }

  constructor (listingId) {
    super(FORM_SELECTOR)

    this.listingId = listingId
  }
  onSubmit () {
    this.disabledSubmitButton()

    $.ajax({
      url: '/api/leads',
      method: 'POST',
      dataType: 'json',
      data: {
        listing_id: this.listingId,
        lead: this.formData(),
        send_quote: true
      }
    }).done((response) => {
      const leadId = response.data.id

      if (response.meta.quote_sent) {
        this.onSuccess(I18n.t('thank_you_for_your_inquery'))
      } else {
        this.onSuccess(I18n.t('thank_you_for_your_request'))
      }

      this.trackRequestQuote(leadId)
    }).fail((error) => {
      this.onError(error)
    }).always(() => {
      this.enableSubmitButton()
    })
  }

  trackRequestQuote (leadId) {
    if (window.onRequestQuoteSubmitted && typeof window.onRequestQuoteSubmitted === 'function') {
      window.onRequestQuoteSubmitted({
        listing_id: this.listingId,
        lead_id: leadId
      })
    }

    if (window.gtagReportConversion) {
      window.gtagReportConversion()
    }

    new Track().send('event', {
      event_category: 'request_quote',
      event_action: 'submit',
      listing_ids: [this.listingId]
    })
  }
}

export default RequestQuoteForm
