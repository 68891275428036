import SortingMore from 'listings/scripts/sorting_more'
import DynamicModal from 'modals/scripts/dynamic_modal'

const MODAL_SELECTOR = '#sorting_more_modal'

class SortingMoreButton {
  static async init () {
    $('.js-sorting-more-btn').on('click', (e) => {
      const modal = new DynamicModal(MODAL_SELECTOR, { size: 'sm' })

      const dataQuery = jQuery.parseJSON($('code[data-query-params]').attr('data-query-params') || '{}')
      const cleanedDataQuery = dataQuery
      delete cleanedDataQuery['path']

      $.ajax({
        url: '/api/filterable_specification_fields/',
        dataType: 'json',
        data: { field: 'specifications', ...cleanedDataQuery }
      }).done((data) => {
        SortingMore.render(modal.$body, data)

        modal.open()
      }).fail((error) => console.log(error))
    })
  }
}

export default SortingMoreButton
