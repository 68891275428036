/* global I18n */

class BaseForm {
  get requiredCustomFieldIds () {
    return $.map($('[data-required=true]'), val => val.id)
  }

  get regexpCustomFieldIds () {
    return $.map($('[data-use-regexp=true]'), val => val)
  }

  get customlRules () {
    const requiredFields = this.requiredCustomFieldIds.reduce((object, id) => {
      object[id.replace('_', ' ')] = { required: true }
      return object
    }, {})

    const regexpFields = this.regexpCustomFieldIds.reduce((object, val) => {
      const isRequired = $(val).attr('data-required') === 'true'
      object[val.id.replaceAll('_', ' ')] = { required: isRequired, regexp: `${$(val).attr('data-regexp')}` }
      return object
    }, {})

    return { ...requiredFields, ...regexpFields }
  }

  get customlMessages () {
    return this.requiredCustomFieldIds.reduce((object, id) => {
      object[id.replace('_', ' ')] = { required: I18n.t('validation.default') }
      return object
    }, {})
  }

  constructor (selector) {
    this.$form = $(selector)
    this.$success = this.$form.parent().find('#successful_form_submission, .successful_form_submission, [data-role="successful_form_submission"]')
    this.$errorAlert = this.$form.find('#error_alert, [data-role="error-alert"]')
    this.isLocationRequired = this.$errorAlert.is('.s667, .s726')
    this.privacyCheckbox = this.$form.find("[name='privacy_policy']")

    if (this.$form.length > 0) {
      this.$form.each((_, form) => {
        const generatedClass = `form-${Math.random().toString(36).substring(7)}`
        form.className = `${generatedClass} ${form.className}`
      })

      const validator = this.$form.validate()

      $.validator.addMethod(
        'regexp',
        function (value, element, regexpString) {
          var regexp = new RegExp(regexpString)
          return this.optional(element) || regexp.test(value)
        },
        I18n.t('validation.default')
      )

      if (validator) {
        validator.destroy()
      }

      this.initValidation()
      this.prefillForm()

      if (!this.$form.find('.btn-captcha').length) {
        this.bindPrivacyCheckbox()
      } else {
        this.bindPrivacyCheckboxCaptcha()
      }

      if (this.privacyCheckbox.length > 0 && !this.privacyCheckbox[0].checked) {
        this.disabledSubmitButton()
      }
    }
  }

  initValidation () {
    const isLocationRequired = this.isLocationRequired
    this.$form.each((_, form) => {
      $(form).validate({
        submitHandler: (_, e) => {
          const classNames = e.target.className.split(' ')
          const selectedFormClass = classNames.filter(word => word.startsWith('form-'))
          window._formSelected = `.${selectedFormClass}`
          this.onSubmit(e)
        },
        rules: {
          first_name: {
            required: true
          },
          last_name: {
            required: $(form).find('#last_name').prop('required')
          },
          email: {
            required: true,
            email: true
          },
          location: {
            required: isLocationRequired || $(form).find('#location').prop('required')
          },
          phone: {
            required: $(form).find('#phone').prop('required')
          },
          message: {
            required: $(form).find('#message').prop('required')
          },
          ...this.customlRules
        },
        messages: {
          first_name: {
            required: I18n.t('validation.first_name')
          },
          last_name: {
            required: I18n.t('validation.last_name')
          },
          email: {
            required: I18n.t('validation.email'),
            email: I18n.t('validation.email')
          },
          phone: {
            required: I18n.t('validation.phone')
          },
          location: {
            required: I18n.t('validation.location')
          },
          message: {
            required: I18n.t('validation.message')
          },
          ...this.customlMessages
        },
        errorPlacement: (error, element) => {
          if (element.is(':radio')) {
            error.appendTo(element.parents('.radio-wrapper'))
          } else if (element.is(':checkbox')) {
            error.appendTo(element.parents('.checkbox-wrapper'))
          } else {
            error.insertAfter(element)
          }
        }
      })
    })
  }

  disabledSubmitButton () {
    this.$form.find('[type="submit"]').attr('disabled', true).addClass('disabled')
  }
  enableSubmitButton () {
    this.$form.find('[type="submit"]').removeAttr('disabled').removeClass('disabled')
  }
  formData () {
    if (this.$form.length > 0 && window._formSelected) {
      const selectedForm = $(this.$form.filter(window._formSelected)).serializeArray()

      const defaultFields = selectedForm.reduce((acc, item) => {
        acc[item.name] = item.value
        return acc
      }, {})

      return {
        ...this.cleanedFields(defaultFields),
        ...this.extractCheckedboxes(selectedForm)
      }
    } else {
      const defaultFields = this.$form.serializeArray().reduce((acc, item) => {
        acc[item.name] = item.value
        return acc
      }, {})

      return {
        ...this.cleanedFields(defaultFields),
        ...this.extractCheckedboxes(this.$form.serializeArray())
      }
    }
  }

  cleanedFields (fields) {
    delete fields['enable_analytics']
    delete fields['g-recaptcha-response']

    Object.keys(fields).forEach(key => {
      if (key.match('checkbox_input:')) delete fields[key]
    })

    return fields
  }

  extractCheckedboxes (serializedForm) {
    const checkboxValues = {}
    const checkboxes = serializedForm.filter(el => el.name.match('checkbox_input:'))

    checkboxes.forEach(field => {
      const name = field.name.replace('checkbox_input:', '')

      if (checkboxValues[name]) {
        checkboxValues[name] = `${checkboxValues[name]}, ${field.value}`
      } else {
        checkboxValues[name] = field.value
      }
    })

    return checkboxValues
  }

  onSuccess (message) {
    if (message) {
      this.$success.html(message)
    }

    this.$form.hide()
    this.$success.show()

    localStorage.setItem('contacts', JSON.stringify({
      ...this.formData(),
      message: null
    }))

    if (window.onSuccess && typeof window.onSuccess === 'function') {
      window.onSuccess()
    }
  }
  showErrorMessage (error) {
    this.$errorAlert.find('.error-message').text(error)
    this.$errorAlert.show()
    this.$errorAlert[0].scrollIntoView()
  }
  onError (error) {
    if (error.status === 422) {
      const errors = JSON.parse(error.responseText)['errors'].map((error) => {
        return error.detail
      }).join('<br>')
      this.showErrorMessage(errors)
    } else {
      this.showErrorMessage(I18n.t('internal_server_error'))
    }
  }
  prefillForm () {
    const contacts = JSON.parse(localStorage.getItem('contacts') || '{}')

    if (contacts && Object.keys(contacts).length > 0) {
      this.$form.find('textarea:not([name="message"]), input:not([type="hidden"]):not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not([autocomplete="off"])').each((index, input) => {
        const value = contacts[input.name]
        if (value) {
          input.value = value
        } else {
          input.value = ''
        }
      })
    }
  }

  bindPrivacyCheckbox () {
    this.privacyCheckbox.on('change', (e) => e.target.checked ? this.enableSubmitButton() : this.disabledSubmitButton())
  }

  bindPrivacyCheckboxCaptcha () {
    this.privacyCheckbox.on('change', (e) => {
      if (e.target.checked && this.$form.find('.captcha-solved').length) {
        this.enableSubmitButton()
      } else {
        this.disabledSubmitButton()
      }
    })
  }

  reset () {
    this.$success.hide()
    this.$form.show()
    this.$errorAlert.hide()
  }
}

export default BaseForm
