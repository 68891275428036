window.lazyFunctions = {
  lazyLoadRecaptcha: (element) => {
    const { script } = element.dataset

    const recaptchaApi = document.getElementById('recaptcha-api')
    if (recaptchaApi) {
      recaptchaApi.remove()
    }

    const scriptEl = document.createElement('script')
    scriptEl.setAttribute('src', script)
    scriptEl.setAttribute('type', 'text/javascript')
    scriptEl.setAttribute('defer', true)
    scriptEl.setAttribute('id', 'recaptcha-api')
    scriptEl.setAttribute('async', true)
    document.head.appendChild(scriptEl)

    if (window.hcaptcha) {
      window.hcaptcha.render('h-captcha')
    }
  }
}

export const executeLazyScript = (element) => {
  const lazyFunctionName = element.getAttribute('data-lazy-function')
  const lazyFunction = window.lazyFunctions[lazyFunctionName]
  if (!lazyFunction) return
  lazyFunction(element)
}
