class ToTheTop {
  static async init () {
    const toTheTop = $('.to-the-top')
    toTheTop.on('click', (event) => {
      event.preventDefault()
      $('html, body').animate({
        scrollTop: 0
      }, 'slow')
    })
  }
}

export default ToTheTop
