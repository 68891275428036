const SEARCH_INPUT_SELECTOR = '[data-role="blog_posts_searchbar"]'

class BlogPostsSearchInput {
  static async init () {
    if (!$(SEARCH_INPUT_SELECTOR)[0]) return

    const dataQuery = jQuery.parseJSON($('code[data-blog-posts-query-params]').attr('data-blog-posts-query-params') || '{}')
    const blogPostsPath = (dataQuery.path || 'blog') + '?'

    const cleanedDataQuery = dataQuery
    delete cleanedDataQuery['path']
    delete cleanedDataQuery['q']

    $(SEARCH_INPUT_SELECTOR).on('search', (e) => {
      if (e.currentTarget.value === '') {
        window.location.href = blogPostsPath + $.param(cleanedDataQuery)
      }
    })
  }
}

export default BlogPostsSearchInput
